<template>
  <div class="section__lk-content container-shadow fill-white region-team" v-if="regionteam">
    <div v-if="$route.name === 'RegionTeamForm'" class="section__lk-title">Форма подачи сведений о региональной управленческой команде</div>
    <template v-if="regionteam && (regionteam.status === 'draft' || regionteam.status === 'rejected')">
      <template v-if="regionteam.status === 'rejected'">
        <h3 v-if="comments.length">Комментарии от администратора</h3>
        <admin-comments :comments="comments"/>
      </template>
      <router-view />
    </template>
    <div v-else-if="regionteam.status === 'send'" class="text-center mt-2 mb-5 row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M126 24H108V18C108 16.4087 107.368 14.8826 106.243 13.7574C105.117 12.6321 103.591 12 102 12H42C40.4087 12 38.8826 12.6321 37.7574 13.7574C36.6321 14.8826 36 16.4087 36 18V24H18C16.4087 24 14.8826 24.6321 13.7574 25.7574C12.6321 26.8826 12 28.4087 12 30V48C12 54.3652 14.5286 60.4697 19.0294 64.9706C23.5303 69.4714 29.6348 72 36 72H45.24C50.6672 78.0549 57.9844 82.0941 66 83.46V96H60C55.2261 96 50.6477 97.8964 47.2721 101.272C43.8964 104.648 42 109.226 42 114V126C42 127.591 42.6321 129.117 43.7574 130.243C44.8826 131.368 46.4087 132 48 132H96C97.5913 132 99.1174 131.368 100.243 130.243C101.368 129.117 102 127.591 102 126V114C102 109.226 100.104 104.648 96.7279 101.272C93.3523 97.8964 88.7739 96 84 96H78V83.46C86.0156 82.0941 93.3328 78.0549 98.76 72H108C114.365 72 120.47 69.4714 124.971 64.9706C129.471 60.4697 132 54.3652 132 48V30C132 28.4087 131.368 26.8826 130.243 25.7574C129.117 24.6321 127.591 24 126 24ZM36 60C32.8174 60 29.7652 58.7357 27.5147 56.4853C25.2643 54.2348 24 51.1826 24 48V36H36V48C36.0132 52.0903 36.7234 56.1483 38.1 60H36ZM84 108C85.5913 108 87.1174 108.632 88.2426 109.757C89.3679 110.883 90 112.409 90 114V120H54V114C54 112.409 54.6321 110.883 55.7574 109.757C56.8826 108.632 58.4087 108 60 108H84ZM96 48C96 54.3652 93.4714 60.4697 88.9706 64.9706C84.4697 69.4714 78.3652 72 72 72C65.6348 72 59.5303 69.4714 55.0294 64.9706C50.5286 60.4697 48 54.3652 48 48V24H96V48ZM120 48C120 51.1826 118.736 54.2348 116.485 56.4853C114.235 58.7357 111.183 60 108 60H105.9C107.277 56.1483 107.987 52.0903 108 48V36H120V48Z" fill="#BDBDBD"/>
        </svg>
        <h3 class="m-0 mt-2">Сведения о региональной управленческой команде успешно отправлены!</h3>
        <h3 class="mt-1">В случае, если необходимо скорректировать направленные сведения, нажмите кнопку Редактировать сведения</h3>
        <button class="btn btn-primary" @click="putRegionteam({status: 'draft', id: regionteam.id})">Редактировать сведения</button>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div v-else-if="regionteam.status === 'approved'" class="text-center mt-2 row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <svg width="144" height="144" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M126 24H108V18C108 16.4087 107.368 14.8826 106.243 13.7574C105.117 12.6321 103.591 12 102 12H42C40.4087 12 38.8826 12.6321 37.7574 13.7574C36.6321 14.8826 36 16.4087 36 18V24H18C16.4087 24 14.8826 24.6321 13.7574 25.7574C12.6321 26.8826 12 28.4087 12 30V48C12 54.3652 14.5286 60.4697 19.0294 64.9706C23.5303 69.4714 29.6348 72 36 72H45.24C50.6672 78.0549 57.9844 82.0941 66 83.46V96H60C55.2261 96 50.6477 97.8964 47.2721 101.272C43.8964 104.648 42 109.226 42 114V126C42 127.591 42.6321 129.117 43.7574 130.243C44.8826 131.368 46.4087 132 48 132H96C97.5913 132 99.1174 131.368 100.243 130.243C101.368 129.117 102 127.591 102 126V114C102 109.226 100.104 104.648 96.7279 101.272C93.3523 97.8964 88.7739 96 84 96H78V83.46C86.0156 82.0941 93.3328 78.0549 98.76 72H108C114.365 72 120.47 69.4714 124.971 64.9706C129.471 60.4697 132 54.3652 132 48V30C132 28.4087 131.368 26.8826 130.243 25.7574C129.117 24.6321 127.591 24 126 24ZM36 60C32.8174 60 29.7652 58.7357 27.5147 56.4853C25.2643 54.2348 24 51.1826 24 48V36H36V48C36.0132 52.0903 36.7234 56.1483 38.1 60H36ZM84 108C85.5913 108 87.1174 108.632 88.2426 109.757C89.3679 110.883 90 112.409 90 114V120H54V114C54 112.409 54.6321 110.883 55.7574 109.757C56.8826 108.632 58.4087 108 60 108H84ZM96 48C96 54.3652 93.4714 60.4697 88.9706 64.9706C84.4697 69.4714 78.3652 72 72 72C65.6348 72 59.5303 69.4714 55.0294 64.9706C50.5286 60.4697 48 54.3652 48 48V24H96V48ZM120 48C120 51.1826 118.736 54.2348 116.485 56.4853C114.235 58.7357 111.183 60 108 60H105.9C107.277 56.1483 107.987 52.0903 108 48V36H120V48Z" fill="#BDBDBD"/>
        </svg>
        <h3 class="m-0 mt-2">Поздравляем, поданные сведения о региональной управленческой команде рассмотрены и подтверждены сотрудниками Школы экспорта РЭЦ!</h3>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AdminComments from "@/components/AdminComments.vue";

export default {
  name: "RegionTeam",
  components: {AdminComments},
  mounted() {
    if(!this.regionteam) {
      this.getRegionteam()
    }
  },
  methods: {
    ...mapActions(['getRegionteam', 'putRegionteam'])
  },
  computed: {
    ...mapGetters(['regionteam']),
    comments() {
      return  this.regionteam.comment ? [{
        comment: this.regionteam.comment,
        created_at: this.regionteam.modified_at
      }] : []
    }
  }
}
</script>

<style scoped>

</style>